import React, { FC, forwardRef } from 'react';
import { SvgIconProps } from '@material-ui/core';
import LedLocationSvg from './led-location.svg';
import LedLocationActiveSvg from './led-location-active.svg';
import LedBuildingSvg from './led-building.svg';
import LedBuildingActiveSvg from './led-building-active.svg';
import LedSearchSvg from './led-search.svg';
import LedSearchActiveSvg from './led-search-active.svg';
import LedSummarySvg from './led-summary.svg';
import LedSummaryActiveSvg from './led-summary-active.svg';
import LedOrderSvg from './led-order.svg';
import LedOrderActiveSvg from './led-order-active.svg';
import LedLargeLogoSvg from './led-large-logo.svg';
import LedMobileLogoSvg from './led-mobile-logo.svg';
import CalculatorInputSvg from './calculator-input.svg';
import LedCheckSvg from './led-check.svg';
import LedUncheckSvg from './led-uncheck.svg';
import ArrowIconLedSvg from './arrow.svg';
import LedArrowSelectSvg from './led-arrow-select.svg';
import LedPlusIconSvg from './led-plus-icon.svg';
import LedDivisionSvg from './led-division.svg';
import LedEqualSvg from './led-equal.svg';
import ErrorInputSvg from './error-input.svg';
import LedUploadSvg from './led-upload.svg';
import LedPlusWhiteIconSvg from './led-plus-white-icon.svg';
import LedMinusIconSvg from './led-minus-icon.svg';
import LedDeleteSvg from './led-delete.svg';
import LedAnnualConsumptionSvg from './led-annual-consumption.svg';
import LedCostOfEnergySvg from './led-cost-of-energy.svg';
import LedTotalFixturesSvg from './led-total-fixtures.svg';
import LedNumberOfAreasSvg from './led-number-of-areas.svg';
import LedConfirmationIconSvg from './led-confirmation-icon.svg';
import LedCloseIconSvg from './led-close-icon.svg';
import LedInfoIconSvg from './led-info-icon.svg';
import UploadMobileIconSvg from './upload-mobile.svg';
import ResetCircleIconSvg from './reset-circle.svg';
import CloseXIconSvg from './led-close-x.svg';
import LightningIconSvg from './led-lightning-icon.svg';
import MagnifierIconSvg from './led-magnifier.svg';
import ThermometerIconSvg from './led-thermometer.svg';
import ArrowUpSvg from './arrow-up.svg';
import TimeIconSvg from './time-icon.svg';
import BinIconSvg from './led-bin-icon.svg';
import TrashLogoSvg from './led-trash-logo.svg';
import BusinessDetailsSvg from './led-business-details.svg';
import LightingAuditSvg from './led-lighting-audit.svg';
import RetrofitsSvg from './led-retrofit.svg';
import UpgradeSvg from './led-upgrade.svg';
import EmailIconSvg from './led-email-icon.svg';
import ArrowDownIconSvg from './arrow-down.svg';
import CongratulationsIconSvg from './led-congratulations-icon.svg';
import UserLogoSvg from './led-user-logo.svg';
import AddressLogoSvg from './led-adress-logo.svg';
import GreyArrowIconSvg from './led-grey-arrow.svg';
import AddressBillingIconSvg from './address-billing-icon.svg';
import ChosenReplacementIconSvg from './chosen-replacement-icon.svg';
import CloseReplacementIconSvg from './led-close-replacement-icon.svg';
import RefreshIconSvg from './refresh-icon.svg';
import BigArrowIconLedSvg from './big-arrow.svg';
import LedWattageIconSvg from './led-wattage-icon.svg';
import LedTempIconSvg from './led-temp-icon.svg';
import LedLumensIconSvg from './led-lumens-icon.svg';
import LedPlaceIconSvg from './led-place-icon.svg';
import LedVoltageIconSvg from './led-voltage-icon.svg';
import LedMountingIconSvg from './led-mounting-icon.svg';
import LumensFluxIconSvg from './lumens-flux-icon.svg';
import LedEnterIconSvg from './led-enter-left.svg';
import LedCloseEmailIconSvg from './led-close-email-icon.svg';
import LedClosePlaceOrderIconSvg from './led-close-place-order-icon.svg';

export const LedInfoIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedInfoIconSvg ref={ref} {...props} />
));

export const LedDeleteIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedDeleteSvg ref={ref} {...props} />
));

export const EqualIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedEqualSvg ref={ref} {...props} />
));

export const DivisionIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedDivisionSvg ref={ref} {...props} />
));

export const LedLocationIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedLocationSvg ref={ref} {...props} />
));

export const LedLocationActiveIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedLocationActiveSvg ref={ref} {...props} />
));

export const LedBuildingIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedBuildingSvg ref={ref} {...props} />
));

export const LedBuildingActiveIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedBuildingActiveSvg ref={ref} {...props} />
));

export const LedSearchIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedSearchSvg ref={ref} {...props} />
));

export const LedSearchActiveIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedSearchActiveSvg ref={ref} {...props} />
));

export const LedSummaryIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedSummarySvg ref={ref} {...props} />
));

export const LedSummaryActiveIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedSummaryActiveSvg ref={ref} {...props} />
));

export const LedOrderIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedOrderSvg ref={ref} {...props} />
));

export const LedOrderActiveIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedOrderActiveSvg ref={ref} {...props} />
));

export const LedLargeLogoIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedLargeLogoSvg ref={ref} {...props} />
));

export const LedMobileLogoIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedMobileLogoSvg ref={ref} {...props} />
));

export const CalculatorInputIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <CalculatorInputSvg ref={ref} {...props} />
));

export const LedCheckIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedCheckSvg ref={ref} {...props} />
));

export const LedUncheckIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedUncheckSvg ref={ref} {...props} />
));

export const LedArrowSelectIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedArrowSelectSvg ref={ref} {...props} />
));

export const LedPlusIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedPlusIconSvg ref={ref} {...props} />
));

export const ArrowLedSvgIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ArrowIconLedSvg ref={ref} {...props} />
));

export const ErrorInputIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ErrorInputSvg ref={ref} {...props} />
));

export const LedUploadIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedUploadSvg ref={ref} {...props} />
));

export const LedPlusWhiteIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedPlusWhiteIconSvg ref={ref} {...props} />
));

export const LedMinusIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedMinusIconSvg ref={ref} {...props} />
));

export const LedAnnualConsumption: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedAnnualConsumptionSvg ref={ref} {...props} />
));

export const LedCostOfEnergy: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedCostOfEnergySvg ref={ref} {...props} />
));

export const LedTotalFixtures: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedTotalFixturesSvg ref={ref} {...props} />
));

export const LedNumberOfAreas: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedNumberOfAreasSvg ref={ref} {...props} />
));

export const LedConfirmationIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedConfirmationIconSvg ref={ref} {...props} />
));

export const LedCloseIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedCloseIconSvg ref={ref} {...props} />
));

export const UploadMobileIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <UploadMobileIconSvg ref={ref} {...props} />
));

export const ResetCircleIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ResetCircleIconSvg ref={ref} {...props} />
));

export const CloseXIconIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <CloseXIconSvg ref={ref} {...props} />
));

export const LightningIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LightningIconSvg ref={ref} {...props} />
));

export const MagnifierIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <MagnifierIconSvg ref={ref} {...props} />
));

export const ThermometerIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ThermometerIconSvg ref={ref} {...props} />
));

export const TimeIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <TimeIconSvg ref={ref} {...props} />
));

export const BinIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <BinIconSvg ref={ref} {...props} />
));

export const TrashLogo: FC<SvgIconProps> = forwardRef((props, ref) => (
  <TrashLogoSvg ref={ref} {...props} />
));

export const BusinessDetails: FC<SvgIconProps> = forwardRef((props, ref) => (
  <BusinessDetailsSvg ref={ref} {...props} />
));

export const LightingAudit: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LightingAuditSvg ref={ref} {...props} />
));

export const Retrofits: FC<SvgIconProps> = forwardRef((props, ref) => (
  <RetrofitsSvg ref={ref} {...props} />
));

export const Upgrade: FC<SvgIconProps> = forwardRef((props, ref) => (
  <UpgradeSvg ref={ref} {...props} />
));

export const EmailIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <EmailIconSvg ref={ref} {...props} />
));

export const ArrowDownIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ArrowDownIconSvg ref={ref} {...props} />
));

export const CongratulationsIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <CongratulationsIconSvg ref={ref} {...props} />
));

export const UserLogo: FC<SvgIconProps> = forwardRef((props, ref) => (
  <UserLogoSvg ref={ref} {...props} />
));

export const AddressLogo: FC<SvgIconProps> = forwardRef((props, ref) => (
  <AddressLogoSvg ref={ref} {...props} />
));

export const GreyArrowIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <GreyArrowIconSvg ref={ref} {...props} />
));

export const AddressBillingIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <AddressBillingIconSvg ref={ref} {...props} />
));

export const ChosenReplacementIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ChosenReplacementIconSvg ref={ref} {...props} />
));

export const CloseReplacementIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <CloseReplacementIconSvg ref={ref} {...props} />
));

export const RefreshIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <RefreshIconSvg ref={ref} {...props} />
));

export const LedWattageIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedWattageIconSvg ref={ref} {...props} />
));

export const LedTempIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedTempIconSvg ref={ref} {...props} />
));

export const LedLumensIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedLumensIconSvg ref={ref} {...props} />
));

export const LedPlaceIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedPlaceIconSvg ref={ref} {...props} />
));

export const LedVoltageIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedVoltageIconSvg ref={ref} {...props} />
));

export const LedMountingIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedMountingIconSvg ref={ref} {...props} />
));

export const LedEnterIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedEnterIconSvg ref={ref} {...props} />
));

export const LumensFluxIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LumensFluxIconSvg ref={ref} {...props} />
));

export const LedCloseEmailIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedCloseEmailIconSvg ref={ref} {...props} />
));

export const LedClosePlaceOrderIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LedClosePlaceOrderIconSvg ref={ref} {...props} />
));

interface Position {
  position: 'right' | 'left' | 'up' | 'down';
}

export enum PositionValues {
  left = 0,
  up = 90,
  right = 180,
  down = 360,
}

interface SvgIconPositionProps extends SvgIconProps, Position {}

export const ArrowLedSvgIconPos: FC<SvgIconPositionProps> = forwardRef((props, ref) => (
  <ArrowIconLedSvg
    style={{ transform: `rotate(${PositionValues[props.position]}deg)` }}
    ref={ref}
    {...props}
  />
));

export const BigArrowLedSvgIconPos: FC<SvgIconPositionProps> = forwardRef((props, ref) => (
  <BigArrowIconLedSvg
    style={{ transform: `rotate(${PositionValues[props.position]}deg)` }}
    ref={ref}
    {...props}
  />
));

export const ArrowUpPos: FC<SvgIconPositionProps> = forwardRef((props, ref) => (
  <ArrowUpSvg
    style={{ transform: `rotate(${PositionValues[props.position]}deg)` }}
    ref={ref}
    {...props}
  />
));
